export function translateDate(date) {
  if (!date)
  {
    return undefined;
  }
  const admDate = new Date(date.toString());
  const ret = new Date(
    admDate.getUTCFullYear(),
    admDate.getUTCMonth(),
    admDate.getUTCDate(),
    0,
    0,
    0,
    0
  );
  return ret;
}
