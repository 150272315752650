
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  PropType,
  watch,
} from "vue";
import { string } from "yup/lib/locale";
import { getCountries } from "@/api/country.api";
import Multiselect from "@vueform/multiselect";

export interface Data {
  country?: any;
  countries: [];
  states: [];
  isLoaded: boolean;
}

export default defineComponent({
  name: "CountryComponent",
  props: ["modelValue", "disabled", "classes"],
  emits: ["update:modelValue", "countryIdChanged"],
  components: { Multiselect },
  setup(props, ctx) {
    let data = reactive<Data>({
      countries: [],
      states: [],
      isLoaded: false,
    });
    function onChange(modelData) {
      ctx.emit("update:modelValue", modelData.toString());
    }
    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue) {
          data.country = props.modelValue;
        } else {
          data.country = null;
        }
      }
    );
    function onClear() {
      ctx.emit("update:modelValue", null);
    }

    onMounted(async () => {
      data.country = props.modelValue;
      data.countries = await getCountries();
      if (!data.country) {
        data.country = 1;
        ctx.emit("update:modelValue", data.country.toString());
      }
      data.isLoaded = true;
    });

    return {
      data,
      onChange,
      onClear,
    };
  },
});
