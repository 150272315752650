import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/country";

export async function getCountries() {
  return await ApiService.get(url)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
