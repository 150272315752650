
import { computed, defineComponent, nextTick, watch, ref } from "vue";
import { mask } from "maska";

export default defineComponent({
  name: "PhoneNumberComponent",
  props: ["modelValue","id","class", "disabled"],
  emits: ["update:modelValue"],
  setup(props, ctx) {
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        // strip out formatting characters before returning to parent component
        let temp = (value as string).replaceAll(/[()-]/g, "");
        // only updates on <=10 digit phone numbers, necessary failsafe for certain edgecases
        if (temp.length <= 10) {
          ctx.emit('update:modelValue', temp);
        }
      }
    });

    const inputRef = ref<HTMLInputElement | null>(null);
    watch(value, (newValue, oldValue) => {
      // this is necessary to handle `props.modelValue` being updated in parent component after mount
      // may need to account for more edgecases here in the future
      nextTick(() => {
        if (inputRef.value && oldValue === undefined) {
          let temp = mask(newValue, "(###)-###-####", undefined);
          inputRef.value.value = temp;
        }
      });
    });

    const disabledDisplay = computed(() => {
      if (value?.value) {
          return mask(value.value, "(###)-###-####", undefined);
      }
      else {
        return null;
      }
    });

    return {
      value,
      inputRef,
      disabledDisplay
    }
  }
})
