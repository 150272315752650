<template>
  <input
    ref="inputRef"
    type="text"
    :disabled="disabled"
    :placeholder = "placeholder"
    class="form-control form-control-sm"
    @keyup="edit()"
  />
</template>
<style></style>
<script>
import { useCurrencyInput } from "vue-currency-input";
import { watch } from "vue";
import { boolean } from "yup/lib/locale";

export default {
  name: "DecimalInput",
  props: {
    modelValue: Number, // Vue 2: value
    valueRange: Number,
    minValue: Number,
    precision: Number,
    precisionMin: Number,
    disabled: boolean,
    currencyDisplay: Text,
    maxNumbers: Number,
    placeholder: Text,
  },
  methods: {
    edit() {
      this.$emit("edit");
    },
  },
  setup(props, ctx) {
    let currencyDisplay = "hidden";
    if (props.currencyDisplay) {
      // eslint-disable-next-line vue/no-setup-props-destructure
      currencyDisplay = props.currencyDisplay;
    }

    const options = {
      locale: "en-US",
      currency: "USD",
      currencyDisplay: currencyDisplay,
      precision: {
        min: 1,
        max: 8,
      },
      decimalLength: {
        min: 1,
        max: 2,
      },
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      autoSign: true,
      useGrouping: true,
      accountingSign: false,
    };

    if (props.valueRange) {
      options.valueRange = { max: props.valueRange, min: props.minValue };
    }
    // eslint-disable-next-line vue/no-setup-props-destructure
    options.precision.max = props.precision;
    // eslint-disable-next-line vue/no-setup-props-destructure
    options.precision.min = props.precisionMin;

    let { inputRef, setValue, setOptions, formattedValue } = useCurrencyInput(options);

    watch(
      () => props.modelValue, // Vue 2: props.value
      (value) => {
        setValue(value);        
      }
    );

    watch(
      () => props.valueRange, // Vue 2: props.value
      (value) => {
        options.valueRange = { max: props.valueRange, min: props.minValue };
        setOptions(options)
      }
    );
    return { inputRef, formattedValue };
  },
};
</script>
