
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  PropType,
  watch,
  nextTick,
} from "vue";
import { boolean, string } from "yup/lib/locale";
import Multiselect from "@vueform/multiselect";
import { getCountries } from "@/api/country.api";

export interface Data {
  country?: any;
  state?: any;
  states: [];
  countries: { countryId: number; name: string; states: [] }[];
  disabled?: boolean;
  isLoaded: boolean;
}

export default defineComponent({
  name: "StateComponent",
  props: ["modelValue", "countryId", "disabled", "classes"],
  emits: ["update:modelValue"],
  components: { Multiselect },
  setup(props, ctx) {
    let data = reactive<Data>({
      state: null,
      states: [],
      countries: [],
      isLoaded: false,
      disabled: false,
    });
    watch(
      () => props.countryId,
      async () => {
        let countryId = props.countryId;
        if (!countryId) {
          countryId = 1;
        }
        if (countryId && data.country != countryId) {
          const country = data.countries.find((item) => {
            return item.countryId == countryId;
          });
          if (country) {
            data.country = countryId;
            data.states = country.states;
            //if(data.state && !data.states.find((item: any)=>{ return item.stateCode==data.state })){
            //data.state = null;
            ctx.emit("update:modelValue", null);
            //}
          }
        }
      }
    );
    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue) {
          data.state = props.modelValue;
        } else {
          data.state = null;
        }
      }
    );
    function onChange(modelData) {
      ctx.emit("update:modelValue", modelData);
    }
    function onClear() {
      ctx.emit("update:modelValue", null);
    }

    onMounted(async () => {
      data.disabled = props.disabled;
      data.countries = await getCountries();
      data.state = props.modelValue;
      let countryId = props.countryId;
      if (!countryId) {
        countryId = 1;
      }
      data.country = countryId;
      var country = data.countries.find((item) => {
        return item.countryId == countryId;
      });
      if (country) data.states = country.states;
      data.isLoaded = true;
    });

    return {
      data,
      onChange,
      onClear,
    };
  },
});
