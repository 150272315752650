import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

const url = "api/v1/patients";


export async function searchByName(request) {
  return await ApiService.post(url + "/searchByName", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
export async function searchPatients(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      //TODO DEBT
      data.data.forEach((item: any) => {
        item.dob = new Date(item.dob);
      });
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchWithUnapplied(request) {
  return await ApiService.post(url + "/searchWithUnapplied", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}



export async function getPatients() {
  const request = {
    keyword: "",
    pageNumber: 0,
    pageSize: 1000000,
    orderBy: ["id"],
  };
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const items = res.data.data;
      //TODO DEBT
      items.forEach((item: any) => {
        item.dob = new Date(item.dob);
      });
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addPatient(patient) {
  await ApiService.post(url, patient as never)
    .then()
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updatePatient(patient) {
  if (patient) {
    return await ApiService.put(url + "/" + patient.id, patient as never)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function reviewDate(patiemtId, date) {
  return await ApiService.post(url + "/reviewDate", {
    id: patiemtId,
    reviewDate: date,
  } as never).catch(({ response }) => {
    processErrors(response);
  });
}

export async function accountStatus(patiemtId, accountStatus) {
  return await ApiService.post(url + "/accountStatus", {
    id: patiemtId,
    accountStatus: accountStatus,
  } as never).catch(({ response }) => {
    processErrors(response);
  });
}


export async function loadReviewDate(request) {
  return await ApiService.post(url + "/loadReviewDates", request as never)
    .then((res) => {
      const items = res.data.data;
      //TODO DEBT date formatting
      items.forEach((item: any) => {
        item.reviewDate = new Date(item.reviewDate);
      });
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}


export async function deletePatient(patient) {
  if (patient) {
    return await ApiService.delete(url + "/" + patient?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function getPatient(patientId) {
  return await ApiService.get(url + "/" + patientId)
    .then((res) => {
      const patient = res.data;
      //TODO DEBT
      patient.dob = new Date(patient.dob);
      if (patient.encounters) {
        patient.encounters.forEach((item: any) => {
          item.admissionDate = new Date(item.admissionDate);
          item.dischargeDate = new Date(item.dischargeDate);
          item.statementCoversPeriodFrom = new Date(
            item.statementCoversPeriodFrom
          );
          item.statementCoversPeriodTo = new Date(item.statementCoversPeriodTo);
          item.services.forEach((service: any) => {
            service.serviceDate = new Date(service.serviceDate);
          });
        });
      }

      patient.patientPayers.forEach((item: any) => {
        if (item.effectiveDate)
          item.effectiveDate = new Date(item.effectiveDate);
        if (item.termDate) item.termDate = new Date(item.termDate);
      });

      return patient;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getPatientWithEncounters(patientId) {
  const getpath = url + "/getPatientWithEncounters/" + patientId;
  return await ApiService.get(getpath)
    .then((res) => {
      const patient = res.data;
      return patient;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
//getPatientWithEncounters

export async function postAction(patientActionRequest) {
  return await ApiService.post(
    url + "/postAction",
    patientActionRequest as never
  )
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}